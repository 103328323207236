import React, { useContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeContext, ThemeProvider } from './themes/ThemeContext';
import GlobalStyles from './themes/GlobalStyles';
import { lightTheme, darkTheme } from './themes/theme';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';

const ThemedApp = () => {
  const { theme } = useContext(ThemeContext);
  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  return (
    <StyledThemeProvider theme={currentTheme}>
      <GlobalStyles />
      <div className="container">
        <Header />
        <HomePage />
        <Footer />
      </div>
    </StyledThemeProvider>
  );
};

const App = () => {
  return (
    <ThemeProvider>
      <ThemedApp />
    </ThemeProvider>
  );
};

export default App; 