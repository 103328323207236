import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../themes/ThemeContext';
import LogoDark from '../assets/images/logo-dark.png';
import LogoLight from '../assets/images/logo-light.png';

const HeaderContainer = styled.header`
  padding: 4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  max-width: 400px;
  width: 100%;
  
  @media (max-width: 768px) {
    max-width: 300px;
  }
  
  @media (max-width: 480px) {
    max-width: 270px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = () => {
  const { theme } = useContext(ThemeContext);
  
  return (
    <HeaderContainer>
      <LogoContainer>
        <Logo src={theme === 'dark' ? LogoDark : LogoLight} alt="Bureau Lab Logo" />
      </LogoContainer>
    </HeaderContainer>
  );
};

export default Header; 