import React from 'react';
import styled from 'styled-components';

const HomeContainer = styled.div`
`;

const HomeContent = styled.div`
  margin: 0 auto;
`;

const Paragraph = styled.p`
  font-size: 2.5rem;
  line-height: 1.6;
  margin-top: 2.5rem;
  margin-bottom: 6rem;
`;

const HomePage = () => {
  return (
    <HomeContainer>
      <HomeContent>
        <Paragraph>
        forefront of quant, machine learning, ventures
        </Paragraph>
      </HomeContent>
    </HomeContainer>
  );
};

export default HomePage; 