import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Book.woff2') format('woff2'),
         url('/fonts/Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Medium.woff2') format('woff2'),
         url('/fonts/Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir';
    src: url('/fonts/Avenir-Heavy.woff2') format('woff2'),
         url('/fonts/Avenir-Heavy.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Avenir', sans-serif;
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.3s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.headings};
    font-family: 'Avenir', sans-serif;
    font-weight: bold;
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: ${({ theme }) => theme.secondary};
    }
  }

  img {
    max-width: 100%;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
`;

export default GlobalStyles; 