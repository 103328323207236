export const lightTheme = {
  body: '#FFFFFF',
  text: '#121212',
  headings: '#000000',
  primary: '#4CAF90', // Green color from the logo
  secondary: '#121212',
  background: '#FFFFFF',
  borderColor: '#E1E1E1',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
};

export const darkTheme = {
  body: '#212121',
  text: '#FFFFFF',
  headings: '#FFFFFF',
  primary: '#4CAF90', // Keep the green color consistent
  secondary: '#FFFFFF',
  background: '#212121',
  borderColor: '#333333',
  shadowColor: 'rgba(255, 255, 255, 0.1)',
}; 