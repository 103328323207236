import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../themes/ThemeContext';

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: ${({ theme }) => theme.text};
  transition: color 0.3s ease;
  
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

// Minimalist sun icon
const SunIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="5" stroke="currentColor" strokeWidth="2" />
    <path d="M12 2V4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 20V22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M4 12L2 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M22 12L20 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M19.7782 4.22183L17.6569 6.34315" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M6.34315 17.6569L4.22183 19.7782" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M19.7782 19.7782L17.6569 17.6569" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M6.34315 6.34315L4.22183 4.22183" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

// Minimalist moon icon
const MoonIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <ToggleButton onClick={toggleTheme}>
      {theme === 'light' ? <MoonIcon /> : <SunIcon />}
    </ToggleButton>
  );
};

export default ThemeToggle; 