import React from 'react';
import styled from 'styled-components';
import ThemeToggle from './ThemeToggle';

const FooterContainer = styled.footer`
  padding: 2rem 0;
  border-top: 1px solid ${({ theme }) => theme.borderColor};
  margin-top: 2rem;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  
  @media (max-width: 768px) {
    /* Keep the same layout on smaller screens */
    justify-content: space-between;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ContactInfo = styled.div`
  font-size: 1.3rem;
  
  a {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    
    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const Copyright = styled.div`
  font-size: 1.3rem;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <FooterContainer>
      <FooterContent>
        <LeftSection>
          <ContactInfo>
            <a href="mailto:mail@bureaulab.com">mail@bureaulab.com</a>
          </ContactInfo>
          <Copyright>
            © 2023...{currentYear}
          </Copyright>
        </LeftSection>
        <ThemeToggle />
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer; 